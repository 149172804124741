.c-xtra {
    position: absolute;
    top: rem(-53);
    right: rem(-14);

    @include breakpoint (medium) {
        top: rem(-65);
        width: rem(120);
    }

    img {
        width: rem(80);
        height: auto;
        @include breakpoint (medium) {
            width: rem(120);
        }
    }

    &__button {
        opacity: 0;
        /* duration | easing-function | delay | iteration-count | direction | fill-mode | play-state | name */
        animation: 0.4s ease-in-out 1s 1 fadeIn, 0.7s cubic-bezier(0.32, 0, 0.16, 0.58) 2s 4 bounce;
        animation-fill-mode: forwards;
        transform-origin: center bottom;
    }
}

