html:not(.template--accountminimal) .c-form {
    // exclude oauth from form transitions
    view-transition-class: form;
}
html.template--accountminimal .c-form {
    // exclude oauth from form transitions
    view-transition-name: none !important;
    //view-transition-class: formAccountMinimal;
}

.c-form {
    $topPadding: rem(16);
    $inlinePadding: rem(20);

    position: relative;

    &__inner {
        position: relative;
        padding: $topPadding $inlinePadding rem(4);
        margin: 0 0 $global-margin;
        background: var(--form-bg, cssvar(form-bg));
        color: var(--form-accent, cssvar(form-accent));
        box-shadow: $box-shadow;
        border: none;
        border-radius: cssvar(border-radius);
    }

    &__title {
        @extend .o-headline--size3;
        text-transform: uppercase;
        margin-bottom: rem(6);
    }

    &__message {
        margin: ($topPadding * -1) ($inlinePadding * -1) $global-margin;
        padding: $inlinePadding;
        // background: cssvar(color-mediumlightgray);
        color: var(--form-accent, cssvar(form-accent));
        border-radius: cssvar(border-radius);
        border-bottom: rem(5) solid cssvar(color-secondary);

        &:has(+ div), &:has(+ input) {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        @include tpl(rockantenne) {
            border-color: cssvar(color-background);
        }
        @include tpl(oldieantenne) {
            border-color: cssvar(color-white);
        }

        &-icon {
            display: block;
            width: rem(24);
            height: rem(24);
            margin-top: rem(4);

            @include breakpoint(medium) {
                width: rem(30);
                height: rem(30);
            }
        }

        h3, h4, h5 {
            @extend .o-headline--size3;
            margin: 0;
        }

        // Remove margin-bottom from last paragraph
        p {
            margin-bottom: 0;
            + p {
                margin-top: $global-margin;
            }
        }

        .c-button {
            --button-width: fit-content;
            margin: rem(6) auto 0 0;
        }

        &.c-form__message--loginhint .c-button {
            margin-right: auto;
            margin-left: 0;
        }

        &--success {
            @include tpl(oldieantenne) {
                color: cssvar(color-primary);
                border-color: cssvar(color-success);

                svg {
                    fill: cssvar(color-success);
                }
            }
        }

        &--error {
            color: cssvar(color-alert);
            border-color: cssvar(color-alert);

            @include tpl(rockantenne) {
                background-color: cssvar(color-alert);
                color: cssvar(color-white);
                border-color: cssvar(color-background);
            }
        }

        &:last-child {
            margin-bottom: -4px;
        }
    }

    &__submitbutton {
        @extend .c-button;

        @include tpl(rockantenne) {
            @extend .c-button--secondary;
        }

        margin: $global-margin 0 $global-margin auto;
    }

    /*
    * Basic
    */

    &__privacyhint {
        display: block;
        margin: 0 0 rem(6);
        text-align: end;

        a {
            //font-weight: $global-weight-normal;
            color: var(--form-gray, cssvar(form-gray));
            text-decoration: none;
        }
    }

    fieldset:not(.c-form__inner) {
        margin: 0;
        padding: 0;
        border: none;
    }

    &__field {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        margin-bottom: $global-margin * 1.5;
        overflow-x: hidden;
    }

    &__label {
        display: block;
        margin-bottom: rem(3);
        font-weight: $global-weight-bold;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: currentColor;
    }

    &__optionallabel {
        color: var(--form-gray, cssvar(form-gray));
        font-weight: $global-weight-normal;
        font-size: 0.875em;
        -webkit-font-smoothing: auto;
        -moz-osx-font-smoothing: auto;
    }

    &__fieldhint {
        display: block;
        margin: rem(-2 0 4);
        color: var(--form-gray, cssvar(form-gray));
        font-size: 0.875em;
    }

    &__fielderror {
        display: block;
        margin: rem(-4 0 4);
        color: cssvar(color-alert);
        font-weight: $global-weight-bold;
        font-size: rem(15);
    }

    textarea {
        min-height: rem(130);
        min-width: 100%;
        max-width: 100%; // prevent horizontal resize
    }

    input:not([type="radio"]):not([type="checkbox"]),
    textarea,
    select {
        position: relative;
        display: inline-block;
        width: 100%;
        margin: 0;
        padding: rem(10 8);
        border: rem(1) solid var(--form-accent, cssvar(form-accent));
        background-color: var(--form-input-bkgd-color, var(--form-bg, cssvar(form-bg)));
        line-height: 1.3;
        color: currentColor;
        font-weight: $global-weight-normal;
        border-radius: cssvar(border-radius);

        &::placeholder {
            font-weight: $global-weight-normal;
            color: var(--form-gray, cssvar(form-gray));
        }

        &:focus {
            outline: rem(2) solid currentColor;
            outline-offset: rem(-2);

            @include tpl(rockantenne) {
                outline-color: cssvar(color-secondary);
            }
        }
    }

    &__helper {
        margin-bottom: 0;
        color: var(--form-gray, cssvar(form-gray));
        font-style: italic;
    }

    /*
    * Validation
    */
    &__field {
        input:not([type="radio"]):not([type="checkbox"]),
        textarea {
            & + .c-form__validation {
                pointer-events: none;
                position: relative;
                left: 100%;

                &::before {
                    content: '';
                    position: absolute;
                    top: rem(-30);
                    left: rem(-30);
                    width: rem(17);
                    height: rem(17);
                    mask-repeat: no-repeat;
                    mask-position: center;
                }
            }

            &:not(:placeholder-shown):invalid,
            &.has-error:not(.is-dirty) {
                border-color: cssvar(color-alert);
                box-shadow: inset 0 0 0 rem(1) cssvar(color-alert);

                & + .c-form__validation::before {
                    background-color: cssvar(color-alert);
                    mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 14 15' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.98998 0.833496C3.29304 0.833496 0.299316 3.82016 0.299316 7.50016C0.299316 11.1802 3.29304 14.1668 6.98998 14.1668C10.6936 14.1668 13.694 11.1802 13.694 7.50016C13.694 3.82016 10.6936 0.833496 6.98998 0.833496ZM7.66642 8.16683V4.16683H6.32694V8.16683H7.66642ZM7.66642 10.8335V9.50016H6.32694V10.8335H7.66642ZM1.63879 7.50016C1.63879 10.4468 4.03645 12.8335 6.99668 12.8335C9.95691 12.8335 12.3546 10.4468 12.3546 7.50016C12.3546 4.5535 9.95691 2.16683 6.99668 2.16683C4.03645 2.16683 1.63879 4.5535 1.63879 7.50016Z' /%3E%3C/svg%3E");
                }
            }

            &:disabled {
                border-color: var(--form-gray, cssvar(form-gray));

                & + .c-form__validation::before {
                    background-color: var(--form-gray, cssvar(form-gray));
                    mask-image: url("data:image/svg+xml,%0A%3Csvg viewBox='0 0 14 14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.99668 0.333496C3.29974 0.333496 0.299316 3.32016 0.299316 7.00016C0.299316 10.6802 3.29974 13.6668 6.99668 13.6668C10.6936 13.6668 13.694 10.6802 13.694 7.00016C13.694 3.32016 10.6936 0.333496 6.99668 0.333496ZM1.63879 7.00016C1.63879 4.0535 4.03645 1.66683 6.99668 1.66683C8.23569 1.66683 9.37424 2.08683 10.2784 2.7935L2.77064 10.2668C2.06072 9.36683 1.63879 8.2335 1.63879 7.00016ZM3.71497 11.2068C4.61912 11.9135 5.75767 12.3335 6.99668 12.3335C9.95692 12.3335 12.3546 9.94683 12.3546 7.00016C12.3546 5.76683 11.9326 4.6335 11.2227 3.7335L3.71497 11.2068Z' /%3E%3C/svg%3E");
                }
            }
        }

        // Special handling for password fields due to their toggle-button (for registration and oauth)
        input[type="password"] {
            & + .c-form__validation {
                &::before {
                    left: rem(-52) !important;
                }
            }
            &:not(:placeholder-shown):invalid,
            &.has-error:not(.is-dirty) {
                padding-right: rem(55);
            }
        }
    }

    /*
    * Dropdown
    */
    select {
        appearance: none;
        color: currentColor;

        @include tpl(rockantenne) {
            option {
                background-color: cssvar(color-lightgray);
                color: cssvar(color-black);
            }
        }

        &:invalid, &:disabled, option:disabled {
            color: var(--form-gray, cssvar(form-gray));
        }

        + .c-form__dropdown-icon {
            pointer-events: none;
            display: block;
            position: relative;
            left: calc(100% - #{rem(24)});
            top: rem(-26);
            width: rem(10);
            height: rem(10);
            margin-bottom: rem(-10);
        }
    }

    .c-form__field input[type="password"] {
        padding-right: rem(40);
    }

    &__fieldtoggle {
        position: absolute;
        left: 100%;
        bottom: 0;

        button {
            z-index: 99;
            position: relative;
            top: rem(-2);
            left: -100%;
            padding: 8px 11px;
            line-height: 1;
        }


        svg {
            width: rem(18);
            height: rem(18);
        }
    }

    /*
    * Radio Button, Checkbox & Toggle shared styles
    */
    &__field--radio,
    &__field--checkbox,
    &__field--toggle {
        input {
            cursor: pointer;
            position: absolute;
            left: 0;
            transform: translateY(#{rem(2)});
        }

        input + label {
            font-weight: $global-weight-normal;
            position: relative;
            top: rem(-2);
        }

        label {
            cursor: pointer;
            padding-left: rem(27);
        }

        label + label {
            margin-top: rem(5);
        }

    }

    // Multiple radio/checkbox/toggle below each other have reduced space between themselfs
    &__field--radio + &__field--radio,
    &__field--checkbox + &__field--checkbox,
    &__field--toggle + &__field--toggle {
        margin-top: $global-margin * -1;
    }

    input[type="radio"]:checked+.c-form__field--radio-with-image {
        border-color: cssvar(color-primary);

        label {
            display: block;
            color: cssvar(color-white);
            background-color: cssvar(color-primary);
        }

        span {
            color: cssvar(color-white);
        }

        small {
            color: cssvar(color-lightgray);
        }
    }

    &__field--radio-with-image {
        color: cssvar(color-primary);
        display: block;
        margin-top: rem(10);
        border: 2px solid cssvar(color-mediumlightgray);
        background-color: cssvar(color-mediumlightgray);
        // border-radius: cssvar(border-radius);
        border-bottom-left-radius: cssvar(border-radius);
        border-bottom-right-radius: cssvar(border-radius);
        border-top-left-radius: cssvar(border-radius);
        border-top-right-radius: cssvar(border-radius);

        img {
            border-top-left-radius: cssvar(border-radius);
            border-top-right-radius: cssvar(border-radius);
        }

        span {
            display: flex;
            flex-direction: column;
            padding: rem(2) rem(6);
            line-height: rem(30);
        }

        small {
            margin-left: rem(2);

            @include tpl(rockantenne) {
                color: cssvar(color-darkgray);
            }
        }
    }

    &__field--voting-result-entry {
        margin-bottom: rem(8);
        padding: rem(2 8);
        position: relative;;
        border-radius: cssvar(border-radius);
        border: 1px solid cssvar(color-lightgray);

        @include tpl(rockantenne) {
            border: 1px solid cssvar(color-mediumgray);
        }

        .progress {
            color: cssvar(color-primary);
            background-color: cssvar(color-bluegray);
            border: 1px solid cssvar(color-lightgray);
            opacity: 0.2;
            height: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            // z-index: -1;
        }

        .percent {
            position: absolute;
            top: 50%;
            right: rem(8);
            transform: translate(0, -50%);
        }

        .value {
            width: 80%;
        }
    }

    &__field--radio,
    &__field--checkbox {
        display: flex;

        input {
            display: inline-block;
            appearance: none;
            flex: 0 0 auto;
            width: rem(18);
            height: rem(18);
            margin: 0 rem(10) 0 0;
            border: rem(2) solid var(--form-accent, cssvar(form-accent));
            transition-duration: .4s;
            transition-property: border, background;
        }

        input:checked {
            background-color: var(--form-accent, cssvar(form-accent));
        }
    }

    /*
    * Radio Button
    */
    &__field--radio {
        input {
            border-radius: 50%;
            background-clip: content-box;
            padding: rem(1);
        }
    }

    /*
    * Checkbox
    */
    &__field--checkbox {
        input {
            @include tpl(antenne) {
                border-radius: rem(2); // global-radius looks very wired here, so using 2px instead
            }
        }

        input:checked {
            &::before {
                content: '✓';
                position: absolute;
                top: rem(-4);
                right: 0;
                font-size: rem(17);
                color: var(--form-bg, cssvar(form-bg));
            }
        }
    }

    /*
    * Toggleswtich
    */
    &__field--toggle {
        label {
            position: relative;
            display: flex;
            align-items: center;
            font-weight: $global-weight-normal;
        }

        input {
            opacity: 0;
            width: rem(17);
            height: 0;
        }

        span:first-of-type {
            content: ' ';
            position: absolute;
            width: rem(24);
            height: rem(14);
            border-radius: rem(10);
            background: transparent;
            border: rem(1) solid var(--form-accent, cssvar(form-accent));
            transition-duration: .4s;
            transition-property: border, background;

            &::before {
                content: '';
                position: absolute;
                left: rem(1);
                bottom: rem(1);
                height: rem(10);
                width: rem(10);
                background-color: var(--form-accent, cssvar(form-accent));
                transition: .4s;
                border-radius: 50%;
            }

            +span {
                margin-left: rem(6);
            }
        }

        input:checked+span:first-of-type {
            background-color: var(--form-accent, cssvar(form-accent));

            &::before {
                background-color: var(--form-bg, cssvar(form-bg));
            }
        }

        input:checked+span:first-of-type:before {
            transform: translateX(100%);
        }
    }

    &__field--bday {
        .l-grid__cell:nth-child(1), .l-grid__cell:nth-child(2) {
            width: rem(55);
        }
        .l-grid__cell:nth-child(3) {
            width: rem(90);
        }

        input {
            order: -1;
            appearance: none;
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                appearance: none;
                margin: 0;
            }
        }

        .c-form__field {
            margin-bottom: 0;
        }

        .c-form__field .c-form__label {
            @extend .c-form__fieldhint;
            padding-left: rem(8);
            font-weight: $global-weight-normal;
            margin-bottom: 0;
        }

        .c-form__validation {
            display: none;
        }
    }

    &__loginwall {
        z-index: 100;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: rem(32 0);
        border-radius: cssvar(border-radius);

        &::before {
            content: "";
            display: block;
            position: absolute;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background: var(--form-bg, cssvar(form-bg));
            opacity: 0.85;
            backdrop-filter: blur(4px);
            border-radius: cssvar(border-radius);
        }

        @include breakpoint(medium) {
            align-items: center;
        }

        .c-wall--regwall {
            min-height: 0;
        }

        .c-wall__header, .c-wall__footer {
            margin: rem(16 0);
            width: 100%;
        }

        .c-wall__logo, .c-wall__keyvisual, .c-wall__bg {
            display: none;
        }

        .c-wall--regwall .c-wall__footer small {
            margin-top: rem(32);
        }
    }

    /*
    * Autocomplete
    */

    &__field--autocomplete {
        overflow: visible;
        color: cssvar(color-primary);
        input {
            z-index: 1;
            // Need a real background color because we are positioned
            // above the autocomplete dropdown, which is placed just slightly overlapped.
            --form-input-bkgd-color: #{cssvar(color-foreground)};
        }
    }

    &__autocomplete-results {
        position: absolute;
        top: var(--autocompleteresult-margin-top, rem(40));
        left: 0;
        right: 0;
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;
        background-color: cssvar(color-white) !important;
        max-height: rem(250);
        overflow-y: scroll;
        box-shadow: $box-shadow;
        border-bottom: rem(8) solid cssvar(color-primary);
        border-radius: 0 0 cssvar(border-radius) cssvar(border-radius);

        @include tpl(oldieantenne) {
            border-color: cssvar(color-background);
        }

        li {
            color: cssvar(color-primary);
            display: block;
            text-align: left;
            border-bottom: rem(1) solid cssvar(color-lightgray);

            &:last-child {
                border: none;
            }

            @include tpl(oldieantenne) {
                color: cssvar(color-background);
            }
        }

        span {
            background-color: cssvar(color-primary);
            color: cssvar(color-white);

            @include tpl(oldieantenne) {
                color: cssvar(color-background);
            }
        }

        a, span {
            text-decoration: none;
            display: block;
            padding: rem(15) rem(10);
        }
    }

    /*
    * Themes
    * Used on the weather search inputs
    */

    &--dark-bg {
        --form-accent: #{cssvar(color-white)};
        --form-gray: #{cssvar(color-white)};


        .c-form__field--autocomplete {
            color: cssvar(color-white);
            input {
                --form-input-bkgd-color: #{cssvar(color-foreground)};
            }
        }
    }

    &--dark-bg-medium-down {
        --form-accent: #{cssvar(color-white)};
        --form-gray: #{cssvar(color-white)};

        .c-form__field--autocomplete {
            color: cssvar(color-white);
            input {
                --form-input-bkgd-color: #{cssvar(color-primary)};
            }
        }

        @include breakpoint(large) {
            --form-accent: #{cssvar(form-accent)};
            --form-gray: #{cssvar(form-gray)};

            .c-form__field--autocomplete {
                color: cssvar(color-primary);
                input {
                    --form-input-bkgd-color: #{cssvar(color-white)};
                }
            }
        }
    }
 }

.c-ssoinfo {
    margin: $global-margin 0 0;
    font-size: rem(15);
    color: var(--form-gray, cssvar(form-gray));

    &__icon {
        display: inline-block;
        width: rem(16);
        height: rem(16);
    }

    &__title {
        margin-top: rem(-24);
        margin-left: rem(23);

        button {
            display: inline;
            padding: 0;
            margin: 0;
            text-decoration: underline;
        }
    }

    &__details {
        margin-top: rem(8);
    }
}

/*
 * Sticky Buttons for Quantyoo Consent in Promotion Forms
 */
.o-stickybottom-observer {
    height: 1px;
}
.o-stickybottom.has-jsstickyhandler {
    position: -webkit-sticky;
    position: sticky;
    bottom: rem(73);
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, var(--form-bg, cssvar(form-bg)) 80%);
    padding: rem(16 20 0);
    margin: rem(-16 -20 0);
    pointer-events: none;

    .l-page-oauthcontent & {
        bottom: 0px;
    }
}
.o-stickybottom.has-jsstickyhandler button {
    pointer-events: all;
}
.o-stickybottom.is-disabled .c-qyconsent__acceptbutton {
    background: #999;
}
.o-stickybottom.is-disabled .c-qyconsent__declinebutton {
    background: transparent;
    color: #999;
}
/*
.c-form fieldset > .o-stickybottom.has-jsstickyhandler {
    margin-bottom: -15px;
}
*/
.c-qyconsent {
    h2 {
        font-size:1.375rem;
        margin-top:0;
    }
    ul {
        margin-top:-1rem;
    }
    &__acceptbutton.c-form__submitbutton {
        margin: 0 0 $global-margin auto;
    }
    &__declinebutton.c-button {
        margin: 0 auto $global-margin 0;
        padding-left: 0 !important;
        clip-path: none !important;
    }
}
.c-abyconsent {
    h2 {
        font-size:1.375rem;
        margin-top:0;
    }
    &__acceptbutton.c-form__submitbutton {
        margin: 0 0 $global-margin auto;
    }
    &__declinebutton.c-button {
        margin: 0 auto $global-margin 0;
        clip-path: none !important;
    }
}
