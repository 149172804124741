@charset "UTF-8";

// http://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/
@-ms-viewport {
    width: device-width;
}

@import '../node_modules/modern-css-reset/src/reset';
@import '../node_modules/nprogress/nprogress';

// import helper functions and settings
@import 'functions';
@import 'settings';
@import 'settings-cssproperties';

// Local Imports
@import 'grid';
@import 'layout';
@import 'typography';
@import 'lazyload';
@import 'forms/forms';
@import 'animations';
@import 'objects';
@import 'tables';
@import 'skeleton';

// Components
@import 'components/header';
@import 'components/footer';
@import 'components/offcanvas';
@import 'components/card';
@import 'components/playbutton';
@import 'components/player';
@import 'components/videoplayer';
@import 'components/player-fullscreen';
@import 'components/hero';
@import 'components/tabs';
@import 'components/sociallinks';
@import 'components/link';
@import 'components/tabnav';
@import 'components/slider';
@import 'components/tooltip';
@import 'components/playlist';
@import 'components/contentplaybar';
@import 'components/mainnav';
@import 'components/breadcrumb';
@import 'components/heading';
@import 'components/cover';
@import 'components/toolbar';
@import 'components/image';
@import 'components/accordion';
@import 'components/badge';
@import 'components/webshareui';
@import 'components/dialog';
@import 'components/buttons';
@import 'components/wall';
@import 'components/sharing';
@import 'components/embed';
@import 'components/newsticker';
@import 'components/search';
@import 'components/quiz';
@import 'components/xtra';
@import 'components/tokenhunt';
@import 'components/archive';
@import 'components/weather';
@import 'components/songvoting';
@import 'components/countdown';
@import 'components/rating';
@import 'components/reklame';

// > ADD NEW STUFF HERE!

// This should come late
@import 'webview';
@import 'utils';
@import 'print';
