html.template--webview {
    .l-page-header,
    .l-page-footer,
    .c-fullscreenplayer,
    .c-offcanvas {
        display: none !important;
    }
    .l-page-wrapper:before {
        // transparent gradient
        display: none;
    }
    .l-page-content .l-container--content:last-child {
        padding-bottom: 0; // remove website-footer padding
    }
}

html.template--webview .l-page-wrapper {
    padding-bottom: 1rem;
}

button[data-appback] {
    display: none;
}


/* * * * * * * * * * *
 * Fullscreen Webviews
 * * * * * * * * * * */
// Starting with (major) version 6, we use fullscreen webviews with the native player on top
// See Schlaubayer project https://secure.helpscout.net/conversation/2756679789/19997?viewId=1904971
.template--nativeapp:not(.template--nativeapp-v5) {
    @include setcssvars((
        content-bottom-spacing: 132px,
    ));
    .l-page-wrapper {
        padding-top: calc(env(safe-area-inset-top, 0px) + #{rem(12)});
        padding-bottom: cssvar(safe-area-bottom);

        // Statusbar background (blurry)
        &:before, &:after {
            content: "";
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: env(safe-area-inset-top, 0px);
            mask-image: linear-gradient(to bottom, rgb(0 0 0), rgba(0, 0, 0, 0));
            pointer-events: none;
        }
        &:before {
            z-index: 2;
            background: var(--antenne-color-background);
            opacity: 0.7;
        }
        &:after {
            z-index: 1;
            backdrop-filter: blur(4px);
        }
    }

    [data-navhandlersection="reklame-bb"] {
        margin-top: rem(-16);
    }
    // If we have a billboard ad slot (with childs) we reduce content margin-top
    [data-navhandlersection="reklame-bb"]:has(*) + .l-page-content {
        margin-top: rem(-25);
    }

    // App back/close button
    button[data-appback] {
        display: block;
        z-index: 16777271;
        position: fixed;
        top: max(5px, calc(env(safe-area-inset-top, 0px) - 10px));
        left: 0;
        width: auto;
        height: auto;
        padding: rem(8 12 8 18);
        line-height: 1;

        &:before, &:after {
            content: "";
            display: block;
            position: absolute;
            top: -10px;
            left: 0px;
            right: -10px;
            bottom: -10px;
            pointer-events: none;

        }
        &:before {
            backdrop-filter: blur(4px);
            z-index: -2;
            mask-image: radial-gradient(rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 0) 75%);
        }
        &:after {
            z-index: -1;
            background: radial-gradient(var(--antenne-color-background), transparent 75%);
            mask-image: radial-gradient(rgba(0, 0, 0, 0.6) 10%, rgba(0, 0, 0, 0) 75%);
        }

        svg {
            display: block;
            width: rem(12);
            height: rem(20);
            filter: drop-shadow(0px 0px 6px var(--antenne-color-background));
        }
    }

    #nprogress {
        z-index: 16777270;
        .bar {
            top: max(0px, calc(env(safe-area-inset-top, 0px) - 9px));
        }
    }
}


/* * * * * * * * * * *
 * Inline Webviews
 * * * * * * * * * * */

html.template--inlinewebview {
    @include setcssvar('color-background', '#e5ecf4');

    @include breakpoint(large) {
        body {
            background: cssvar(color-primary);
        }
        .l-page-wrapper {
            max-width: rem(343);
            border-radius: $global-radius;
            margin: 2rem auto;
            background: cssvar(color-background);
        }
    }

    .l-page-wrapper {
        min-height: 0;
        padding: 1rem 1rem 0 !important;
    }
    .l-container {
        padding: 0;
    }

    h1, h2, h3, h4, h5, h6,
    .o-headline--size1,
    .o-headline--size2,
    .o-headline--size3,
    .o-headline--size4,
    .o-headline--size5,
    .o-headline--size6 {
        font-size: rem(18);
    }

    pre.heightInfo {
        font-size: 10px;
        text-align: center;
        @include breakpoint(large) {
            color: #fff;
        }
    }
}

// ABY Blue Webviews
// @include tpl(antenne) {
//     &.template--nativeapp {
//         @include setcssvars((
//             color-background: cssvar(color-primary),
//             color-white: #E5ECF2, // app white / card background
//             color-foreground: #fff,
//         ));

//         .c-button {
//             &, &--primary {
//                 --button-color: #{cssvar(color-primary)};
//                 --button-backgroundcolor: #{cssvar(color-secondary)};
//                 // @include setcssvars((
//                 //     color-primary: cssvar(color-secondary),
//                 //     color-secondary: #004898,
//                 // ));
//             }
//         }
//     }
// }
